import { getSheetIndex } from '../methods/get';
import Store from '../store';

function setadmgrid_select_save(v) {
    Store.admgrid_select_save = v;
}

function setadmgrid_scroll_status(v) {
    Store.admgrid_scroll_status = v;
}

function setadmgridfile(d) {
    Store.admgridfile = d;
}

function setconfig(v) {
    Store.config = v;

    if(Store.admgridfile != null){
        Store.admgridfile[getSheetIndex(Store.currentSheetIndex)].config = v;
    }
}

function setvisibledatarow(v) {
    Store.visibledatarow = v;

    if(Store.admgridfile != null){
        Store.admgridfile[getSheetIndex(Store.currentSheetIndex)].visibledatarow = v;
    }
}

function setvisibledatacolumn(v) {
    Store.visibledatacolumn = v;

    if(Store.admgridfile != null){
        Store.admgridfile[getSheetIndex(Store.currentSheetIndex)].visibledatacolumn = v;
    }
}

export {
    setadmgrid_select_save,
    setadmgrid_scroll_status,
    setadmgridfile,
    setconfig,
    setvisibledatarow,
    setvisibledatacolumn,
}
