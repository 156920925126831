import server from '../controllers/server';
import { admgridlodingHTML, luckyColor } from '../controllers/constant';
import sheetmanage from '../controllers/sheetmanage';
import admgridformula from './formula';
import imageCtrl from '../controllers/imageCtrl';
import dataVerificationCtrl from '../controllers/dataVerificationCtrl';
import pivotTable from '../controllers/pivotTable';
import admgridFreezen from '../controllers/freezen';
import { getSheetIndex } from '../methods/get';
import { admgridextendData } from './extend';
import admgridConfigsetting from '../controllers/admgridConfigsetting';
import editor from './editor';
import admgridcreatesheet from './createsheet';
import Store from '../store';

const defaultConfig = {
    defaultStore:{
        container: null, 
        admgridfile: null,
        defaultcolumnNum: 60, 
        defaultrowNum: 84, 
        fullscreenmode: true,
        devicePixelRatio: 1,
    
        currentSheetIndex: 0,
        calculateSheetIndex: 0,
        flowdata: [],
        config: {},
    
        visibledatarow: [],
        visibledatacolumn: [],
        ch_width: 0,
        rh_height: 0,
    
        cellmainWidth: 0,
        cellmainHeight: 0,
        toolbarHeight: 0,
        infobarHeight: 0,
        calculatebarHeight: 0,
        rowHeaderWidth: 46,
        columnHeaderHeight: 20,
        cellMainSrollBarSize: 12,
        sheetBarHeight: 31,
        statisticBarHeight: 23,
        admgridTableContentHW: [0, 0],
    
        defaultcollen: 73,
        defaultrowlen: 19,
    
        jfcountfuncTimeout: null, 
        jfautoscrollTimeout: null,
    
        admgrid_select_status: false,
        admgrid_select_save: [{ "row": [0, 0], "column": [0, 0] }],
        admgrid_selection_range: [],
    
        admgrid_copy_save: {}, //复制粘贴
        admgrid_paste_iscut: false,
    
        filterchage: true, //筛选
        admgrid_filter_save: { "row": [], "column": [] },
    
        admgrid_sheet_move_status: false,
        admgrid_sheet_move_data: [],
        admgrid_scroll_status: false,
    
        admgridisrefreshdetail: true,
        admgridisrefreshtheme: true,
        admgridcurrentisPivotTable: false,
    
        admgrid_rows_selected_status: false,  //行列标题相关参
        admgrid_cols_selected_status: false,
        admgrid_rows_change_size: false,
        admgrid_rows_change_size_start: [],
        admgrid_cols_change_size: false,
        admgrid_cols_change_size_start: [],
        admgrid_cols_dbclick_timeout: null,
        admgrid_cols_dbclick_times: 0,
    
        admgridCellUpdate: [],
        
        admgrid_shiftpositon: null,
    
        iscopyself: true,
    
        orderbyindex: 0, //排序下标
    
        admgrid_model_move_state: false, //模态框拖动
        admgrid_model_xy: [0, 0],
        admgrid_model_move_obj: null,
    
        admgrid_cell_selected_move: false,  //选区拖动替换
        admgrid_cell_selected_move_index: [],
    
        admgrid_cell_selected_extend: false,  //选区下拉
        admgrid_cell_selected_extend_index: [],
        admgrid_cell_selected_extend_time: null,
    
        clearjfundo: true,
        jfredo: [],
        jfundo: [],
        lang: 'fr', //language
        createChart: '',
        highlightChart: '',
        zIndex: 15,
        chartparam: {
            admgridCurrentChart: null, //current chart_id
            admgridCurrentChartActive: false,
            admgridCurrentChartMove: null, // Debounce state
            admgridCurrentChartMoveTimeout: null,//拖动图表框的节流定时器
            admgridCurrentChartMoveObj: null, //chart DOM object
            admgridCurrentChartMoveXy: null, //上一次操作结束的图表信息，x,y: chart框位置，scrollLeft1,scrollTop1: 滚动条位置
            admgridCurrentChartMoveWinH: null, //左右滚动条滑动距离
            admgridCurrentChartMoveWinW: null, //上下滚动条滑动距离
            admgridCurrentChartResize: null,
            admgridCurrentChartResizeObj: null,
            admgridCurrentChartResizeXy: null,
            admgridCurrentChartResizeWinH: null,
            admgridCurrentChartResizeWinW: null,
            admgridInsertChartTosheetChange: true, // 正在执行撤销
            admgridCurrentChartZIndexRank : 100,
            admgrid_chart_redo_click:false, //撤销重做时标识
            admgridCurrentChartMaxState: false, //图表全屏状态
            jfrefreshchartall: '',
            changeChartCellData: '',
            renderChart: '',
            getChartJson: ''
        },
        functionList:null, //function list explanation
        admgrid_function:null,
        chart_selection: {},
        currentChart: '',
        scrollRefreshSwitch:true,
    
        measureTextCache:{},
        measureTextCellInfoCache:{},
        measureTextCacheTimeOut:null,
        cellOverflowMapCache:{},
    
        zoomRatio:1,
    
        visibledatacolumn_unique:null,
        visibledatarow_unique:null,
    
        showGridLines:true,
    
        toobarObject: {}, //toolbar constant
        inlineStringEditCache:null,
        inlineStringEditRange:null,
    
        fontList:[],

        currentSheetView:"viewNormal",
    
    },    
    defaultFormula:{
        searchFunctionCell: null,
        functionlistPosition: {},
        rangechangeindex: null,
        rangestart: false,
        rangetosheet: null,
        rangeSetValueTo: null,
        func_selectedrange: {}, //函数选区范围
        rangedrag_column_start: false,
        rangedrag_row_start: false,
        rangeResizeObj: null,
        rangeResize: null,
        rangeResizeIndex: null,
        rangeResizexy: null,
        rangeResizeWinH: null,
        rangeResizeWinW: null,
        rangeResizeTo: null,
        rangeMovexy: null,
        rangeMove: false,
        rangeMoveObj: null,
        rangeMoveIndex: null,
        rangeMoveRangedata: null,
        functionHTMLIndex: 0,
        functionRangeIndex: null,
        execvertex: {},
        execFunctionGroupData: null,
        execFunctionExist: null,
        formulaContainSheetList:{},
        cellTextToIndexList:{},
        isFunctionRangeSave: false,
        formulaContainCellList:{},
        execFunctionGlobalData:{},
        groupValuesRefreshData: [],
        functionResizeData: {},
        functionResizeStatus: false,
        functionResizeTimeout: null,
        data_parm_index: 0  //选择公式后参数索引标记
    },
    defaultSheet:{
        sheetMaxIndex: 0,
        nulldata: null,
        mergeCalculationSheet:{},
        checkLoadSheetIndexToDataIndex:{},
        CacheNotLoadControll:[],
    },
    defaultPivotTable:{
        pivotDatas: null,
        pivotSheetIndex: 0,
        pivotDataSheetIndex: 0,
        celldata: null,
        origindata: null,
        pivot_data_type: {},
        pivot_select_save: null,
        column: null,
        row: null,
        values: null,
        filter: null,
        showType: null,
        rowhidden: null,
        selected: null,
        caljs: null,
        initial: true,
        filterparm: null,
        admgrid_pivotTable_select_state: false,
        jgridCurrentPivotInput: null,
        movestate: false,
        moveitemposition: [],
        movesave: {},
        drawPivotTable: true,
        pivotTableBoundary: [12, 6],
    },
    defaultImage:{
        imgItem: {
            type: '3',  //1移动并调整单元格大小 2移动并且不调整单元格的大小 3不要移动单元格并调整其大小
            src: '',  //图片url
            originWidth: null,  //图片原始宽度
            originHeight: null,  //图片原始高度
            default: {
                width: null,  //图片 宽度
                height: null,  //图片 高度
                left: null,  //图片离表格左边的 位置
                top: null,  //图片离表格顶部的 位置
            },
            crop: {
                width: null,  //图片裁剪后 宽度
                height: null,  //图片裁剪后 高度
                offsetLeft: 0,  //图片裁剪后离未裁剪时 左边的位移
                offsetTop: 0,  //图片裁剪后离未裁剪时 顶部的位移
            },
            isFixedPos: false,  //固定位置
            fixedLeft: null,  //固定位置 左位移
            fixedTop: null,  //固定位置 右位移
            border: {
                width: 0,  //边框宽度
                radius: 0,  //边框半径
                style: 'solid',  //边框类型
                color: '#000',  //边框颜色
            }
        },
        images: null,
        currentImgId: null,
        currentWinW: null,
        currentWinH: null,
        resize: null,  
        resizeXY: null,
        move: false,
        moveXY: null,
        cropChange: null,  
        cropChangeXY: null,
        cropChangeObj: null,
        copyImgItemObj: null,
    },
    defaultDataVerification:{
        defaultItem: {
            type: 'dropdown',  //类型
            type2: null,  //
            value1: '',  //
            value2: '',  //
            checked: false,
            remote: false,  //自动远程获取选项
            prohibitInput: false,  //输入数据无效时禁止输入
            hintShow: false,  //选中单元格时显示提示语
            hintText: '',  //
        },
        curItem: null,
        dataVerification: null,
        selectRange: [],
        selectStatus: false,
    }
}

const method = {
    //翻页
    addDataAjax: function(param, index, url, func){
        let _this = this;

        if(index == null){
            index = Store.currentSheetIndex;
        }

        if(url == null){
            url = server.loadSheetUrl;
        }

        $("#admgrid-grid-window-1").append(admgridlodingHTML());
        param.currentPage++;
        
        let dataType = 'application/json;charset=UTF-8';
        let token = sessionStorage.getItem('x-auth-token');

        $.ajax({
            method: 'POST',
            url: url,
            headers: { "x-auth-token": token },
            data: JSON.stringify(param),
            contentType: dataType,
            success: function(d) {
                //d可能为json字符串
                if(typeof d == "string"){
                    d = JSON.parse(d);
                }

                let dataset = d.data;
                
                let newData = dataset.celldata;
                admgridextendData(dataset["row"], newData);

                setTimeout(function(){
                    Store.loadingObj.close()
                }, 500);

                if(func && typeof(func)=="function"){ 
                    func(dataset);
                }
            }
        })
    },
    //重载
    reload: function(param, index, url, func){
        let _this = this;

        if(index == null){
            index = Store.currentSheetIndex;
        }

        if(url == null){
            url = server.loadSheetUrl;
        }

        $("#admgrid-grid-window-1").append(admgridlodingHTML());

        let arg = {"gridKey" : server.gridKey, "index": index};
        param = $.extend(true, param, arg);
        let file = Store.admgridfile[getSheetIndex(index)];

        $.post(url, param, function (d) {
            let dataset = new Function("return " + d)();
            file.celldata = dataset[index.toString()];
            let data = sheetmanage.buildGridData(file);

            setTimeout(function(){
                Store.loadingObj.close()
            }, 500);

            file["data"] = data;
            Store.flowdata = data;
            editor.webWorkerFlowDataCache(data);//worker存数据

            admgridcreatesheet(data[0].length, data.length, data, null, false);
            file["load"] = "1";

            Store.admgrid_select_save.length = 0;
            Store.admgrid_selection_range = [];

            server.saveParam("shs", null, Store.currentSheetIndex);

            sheetmanage.changeSheet(index);

            if(func && typeof(func)=="function"){ 
                func();
            }
        });
    },
    clearSheetByIndex: function(i){
        let index = getSheetIndex(i);
        let sheetfile = Store.admgridfile[index];

        if(!sheetfile.isPivotTable){
            sheetfile.data = [];
            sheetfile.row = Store.defaultrowNum;
            sheetfile.column = Store.defaultcolumnNum;

            sheetfile.chart = [];
            sheetfile.config = null;
            sheetfile.filter = null;
            sheetfile.filter_select = null;
            sheetfile.celldata = [];
            sheetfile.pivotTable = {};
            sheetfile.calcChain = [];
            sheetfile.status = 0;
            sheetfile.load = 0;

            Store.flowdata = [];
            editor.webWorkerFlowDataCache(Store.flowdata);//worker存数据

            $("#"+ Store.container +" .admgrid-data-visualization-chart").remove();
            $("#"+ Store.container +" .admgrid-datavisual-selection-set").remove();

            $("#admgrid-row-count-show, #admgrid-formula-functionrange-select, #admgrid-row-count-show, #admgrid-column-count-show, #admgrid-change-size-line, #admgrid-cell-selected-focus, #admgrid-selection-copy, #admgrid-cell-selected-extend, #admgrid-cell-selected-move, #admgrid-cell-selected").hide();

            delete sheetfile.load;
        }
        else {
            delete Store.admgridfile[index];
        }
    },
    clear: function(index){
        let _this = this;

        if(index == "all"){
            for(let i = 0; i < Store.admgridfile.length; i++){
                let sheetfile = Store.admgridfile[i];
                _this.clearSheetByIndex(sheetfile.index);
            }
            
        }
        else{
            if(index == null){
                index = Store.currentSheetIndex;
            }
            _this.clearSheetByIndex(index);
        }

        sheetmanage.changeSheet(Store.admgridfile[0].index);
    },
    destroy:function(){
        $("#" + Store.container).empty();
        $("body > .admgrid-cols-menu").remove();

        $("#admgrid-modal-dialog-mask, #admgridTextSizeTest, #admgrid-icon-morebtn-div").remove();
        $("#admgrid-input-box").parent().remove();
        $("#admgrid-formula-help-c").remove();
        $(".chartSetting, .admgrid-modal-dialog-slider").remove();

        //document event release
        $(document).off(".admgridEvent");
        $(document).off(".admgridProtection");
        
        //参数重置
        admgridFreezen.initialHorizontal = true;
        admgridFreezen.initialVertical = true;

        let defaultStore = $.extend(true, {}, defaultConfig.defaultStore);
        for(let key in defaultStore){
            if(key in Store){
                Store[key] = defaultStore[key];
            }
        }

        let defaultFormula = $.extend(true, {}, defaultConfig.defaultFormula);
        for(let key in defaultFormula){
            if(key in admgridformula){
                admgridformula[key] = defaultFormula[key];
            }
        }

        let defaultSheet = $.extend(true, {}, defaultConfig.defaultSheet);
        for(let key in defaultSheet){
            if(key in sheetmanage){
                sheetmanage[key] = defaultSheet[key];
            }
        }

        let defaultPivotTable = $.extend(true, {}, defaultConfig.defaultPivotTable);
        for(let key in defaultPivotTable){
            if(key in pivotTable){
                pivotTable[key] = defaultPivotTable[key];
            }
        }

        let defaultImage = $.extend(true, {}, defaultConfig.defaultImage);
        for(let key in defaultImage){
            if(key in imageCtrl){
                imageCtrl[key] = defaultImage[key];
            }
        }

        let defaultDataVerification = $.extend(true, {}, defaultConfig.defaultDataVerification);
        for(let key in defaultDataVerification){
            if(key in dataVerificationCtrl){
                dataVerificationCtrl[key] = defaultDataVerification[key];
            }
        }

        // remove proxy
        Store.asyncLoad = ['core'];
    },
    editorChart:function(c){
        let chart_selection_color = luckyColor[0];
        let chart_id = "admgridEditMode-datav-chart";
        let chart_selection_id = chart_id + "_selection";
        c.chart_id = chart_id;
        let chartTheme = c.chartTheme;
        chartTheme = chartTheme == null ? "default0000" : chartTheme;

        admgrid.insertChartTosheet(c.sheetIndex, c.dataSheetIndex, c.option, c.chartType, c.selfOption, c.defaultOption, c.row, c.column, chart_selection_color, chart_id, chart_selection_id, c.chartStyle, c.rangeConfigCheck, c.rangeRowCheck, c.rangeColCheck, c.chartMarkConfig, c.chartTitleConfig, c.winWidth, c.winHeight, c.scrollLeft, c.scrollTop, chartTheme, c.myWidth, c.myHeight, c.myLeft!=null?parseFloat(c.myLeft):null, c.myTop!=null?parseFloat(c.myTop):null, c.myindexrank, true);

        $("#"+chart_id).find(".admgrid-modal-controll-update").click();
    },
    /**
     * 获取单元格的值
     * @param {name} 函数名称
     * @param {arguments} 函数参数
     */
    createHookFunction:function(){
        let hookName = arguments[0];
        if(admgridConfigsetting.hook && admgridConfigsetting.hook[hookName]!=null && (typeof admgridConfigsetting.hook[hookName] == "function")){
            var args = Array.prototype.slice.apply(arguments);
            args.shift();
            let ret = admgridConfigsetting.hook[hookName].apply(this, args);
            if(ret===false){
                return false;
            }
            else{
                return true;
            }
        }

        return true;
    }

}

export default method;
