import selection from '../controllers/selection';
import menuButton from '../controllers/menuButton';

export default function cleargridelement(event) {
    $("#admgrid-cols-h-hover").hide();
    $("#admgrid-rightclick-menu").hide();

    $("#admgrid-cell-selected-boxs .admgrid-cell-selected").hide();
    $("#admgrid-cols-h-selected .admgrid-cols-h-selected").hide();
    $("#admgrid-rows-h-selected .admgrid-rows-h-selected").hide();

    $("#admgrid-cell-selected-focus").hide();
    $("#admgrid-rows-h-hover").hide();
    $("#admgrid-selection-copy .admgrid-selection-copy").hide();
    $("#admgrid-cols-menu-btn").hide();
    $("#admgrid-row-count-show, #admgrid-column-count-show").hide();
    if (!event) {
        selection.clearcopy(event);
    }
    //else{
    //	selection.clearcopy();
    //}

    //选区下拉icon隐藏
    if($("#admgrid-dropCell-icon").is(":visible")){
        if(event){
            $("#admgrid-dropCell-icon").remove();
        }
    }
    //格式刷
    if(menuButton.admgridPaintModelOn && !event){
        menuButton.cancelPaintModel();
    }
}
