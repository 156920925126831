import admgridFreezen from '../controllers/freezen';
import { admgrid_searcharray } from '../controllers/sheetSearch';
import { admgridrefreshgrid } from '../global/refresh';
import Store from '../store';
import method from '../global/method'

let scrollRequestAnimationFrameIni = true,scrollRequestAnimationFrame = false, scrollTimeOutCancel=null;

function execScroll(){
    let scrollLeft = $("#admgrid-scrollbar-x").scrollLeft(),
        scrollTop = $("#admgrid-scrollbar-y").scrollTop();
    admgridrefreshgrid(scrollLeft, scrollTop);
    scrollRequestAnimationFrame = window.requestAnimationFrame(execScroll);
}

//全局滚动事件
export default function admgridscrollevent(isadjust) {
    let $t = $("#admgrid-cell-main");
    let scrollLeft = $("#admgrid-scrollbar-x").scrollLeft(),
        scrollTop = $("#admgrid-scrollbar-y").scrollTop(),
        canvasHeight = $("#admgridTableContent").height(); // canvas高度

    // clearTimeout(scrollTimeOutCancel);

    // scrollTimeOutCancel = setTimeout(() => {
    //     scrollRequestAnimationFrameIni  = true;
    //     window.cancelAnimationFrame(scrollRequestAnimationFrame);
    // }, 500);

    // if (!!isadjust) {
    //     let scrollHeight = $t.get(0).scrollHeight;
    //     let windowHeight = $t.height();
    //     let scrollWidth = $t.get(0).scrollWidth;
    //     let windowWidth = $t.width();

    //     let maxScrollLeft = scrollWidth - windowWidth;
    //     let maxScrollTop = scrollHeight - windowHeight;

    //     let visibledatacolumn_c = Store.visibledatacolumn, visibledatarow_c = Store.visibledatarow;

    //     if (admgridFreezen.freezenhorizontaldata != null) {
    //         visibledatarow_c = admgridFreezen.freezenhorizontaldata[3];
    //     }

    //     if (admgridFreezen.freezenverticaldata != null) {
    //         visibledatacolumn_c = admgridFreezen.freezenverticaldata[3];
    //     }

    //     let col_ed = admgrid_searcharray(visibledatacolumn_c, scrollLeft);
    //     let row_ed = admgrid_searcharray(visibledatarow_c, scrollTop);

    //     let refreshLeft = scrollLeft , refreshTop = scrollTop;

    //     if (col_ed <= 0) {
    //         scrollLeft = 0;
    //     }
    //     else {
    //         scrollLeft = visibledatacolumn_c[col_ed - 1];
    //     }

    //     if (row_ed <= 0) {
    //         scrollTop = 0;
    //     }
    //     else {
    //         scrollTop = visibledatarow_c[row_ed - 1];
    //     }
    // }

    if (admgridFreezen.freezenhorizontaldata != null) {
        if (scrollTop < admgridFreezen.freezenhorizontaldata[2]) {
            scrollTop = admgridFreezen.freezenhorizontaldata[2];
            $("#admgrid-scrollbar-y").scrollTop(scrollTop);
            return;
        }
    }

    if (admgridFreezen.freezenverticaldata != null) {
        if (scrollLeft < admgridFreezen.freezenverticaldata[2]) {
            scrollLeft = admgridFreezen.freezenverticaldata[2];
            $("#admgrid-scrollbar-x").scrollLeft(scrollLeft);
            return;
        }
    }

    $("#admgrid-cols-h-c").scrollLeft(scrollLeft);//列标题
    $("#admgrid-rows-h").scrollTop(scrollTop);//行标题
    
    $t.scrollLeft(scrollLeft).scrollTop(scrollTop);

    $("#admgrid-input-box-index").css({
        "left": $("#admgrid-input-box").css("left"),
        "top": (parseInt($("#admgrid-input-box").css("top")) - 20) + "px",
        "z-index": $("#admgrid-input-box").css("z-index")
    }).show();

    // if(scrollRequestAnimationFrameIni && Store.scrollRefreshSwitch){
    //     execScroll();
    //     scrollRequestAnimationFrameIni = false;
    // }

    admgridrefreshgrid(scrollLeft, scrollTop);
    

    $("#admgrid-bottom-controll-row").css("left", scrollLeft);

    //有选区且有冻结时，滚动适应
    if(admgridFreezen.freezenhorizontaldata != null || admgridFreezen.freezenverticaldata != null){
        admgridFreezen.scrollAdapt();
    }

    if(!method.createHookFunction("scroll", {scrollLeft, scrollTop, canvasHeight})){ return; }

}
