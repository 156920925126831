
import defaultSetting from './config.js';
import { common_extend } from './utils/util';
import Store from './store';
import { locales } from './locale/locale';
import server from './controllers/server';
import admgridConfigsetting from './controllers/admgridConfigsetting';
import sheetmanage from './controllers/sheetmanage';
import admgridsizeauto from './controllers/resize';
import admgridHandler from './controllers/handler';
import {initialFilterHandler} from './controllers/filter';
import {initialMatrixOperation} from './controllers/matrixOperation';
import {initialSheetBar} from './controllers/sheetBar';
import {formulaBarInitial} from './controllers/formulaBar';
import {rowColumnOperationInitial} from './controllers/rowColumnOperation';
import {keyboardInitial} from './controllers/keyboard';
import {orderByInitial} from './controllers/orderBy';
import {initPlugins} from './controllers/expendPlugins';
import {
    getadmgridfile,
    getadmgrid_select_save,
    getconfig,
} from './methods/get';
import {
    setadmgrid_select_save
} from './methods/set';
import { admgridrefreshgrid, jfrefreshgrid } from './global/refresh';
import functionlist from './function/functionlist';
import { admgridlodingHTML } from './controllers/constant';
import { getcellvalue, getdatabyselection } from './global/getdata';
import { setcellvalue } from './global/setdata';
import { selectHightlightShow } from './controllers/select';
import {zoomInitial} from './controllers/zoom';
import {printInitial} from './controllers/print';
import method from './global/method';

import * as api from './global/api';

import flatpickr from 'flatpickr'
import Mandarin from 'flatpickr/dist/l10n/zh.js'
import { initListener } from './controllers/listener';
import { hideloading, showloading } from './global/loading.js';
import { admgridextendData } from './global/extend.js';

let admgrid = {};

// mount api
// admgrid.api = api;
// Object.assign(admgrid, api);

admgrid = common_extend(api,admgrid);


//创建admgrid表格
admgrid.create = function (setting) {
    method.destroy()
    // Store original parameters for api: toJson
    Store.toJsonOptions = {}
    for(let c in setting){
        if(c !== 'data'){
            Store.toJsonOptions[c] = setting[c];
        }
    }

    let extendsetting = common_extend(defaultSetting, setting);

    let loadurl = extendsetting.loadUrl,
        menu = extendsetting.menu,
        title = extendsetting.title;

    let container = extendsetting.container;
    Store.container = container;
    Store.admgridfile = extendsetting.data;
    Store.defaultcolumnNum = extendsetting.column;
    Store.defaultrowNum = extendsetting.row;
    Store.defaultFontSize = extendsetting.defaultFontSize;
    Store.fullscreenmode = extendsetting.fullscreenmode;
    Store.lang = extendsetting.lang; //language
    Store.allowEdit = extendsetting.allowEdit;
    Store.limitSheetNameLength =  extendsetting.limitSheetNameLength;
    Store.defaultSheetNameMaxLength = extendsetting.defaultSheetNameMaxLength;
    Store.fontList = extendsetting.fontList;
    server.gridKey = extendsetting.gridKey;
    server.loadUrl = extendsetting.loadUrl;
    server.updateUrl = extendsetting.updateUrl;
    server.updateImageUrl = extendsetting.updateImageUrl;
    server.title = extendsetting.title;
    server.loadSheetUrl = extendsetting.loadSheetUrl;
    server.allowUpdate = extendsetting.allowUpdate;

    admgridConfigsetting.autoFormatw = extendsetting.autoFormatw;
    admgridConfigsetting.accuracy = extendsetting.accuracy;
    admgridConfigsetting.total = extendsetting.data[0].total;

    admgridConfigsetting.loading = extendsetting.loading;
    admgridConfigsetting.allowCopy = extendsetting.allowCopy;
    admgridConfigsetting.showtoolbar = extendsetting.showtoolbar;
    admgridConfigsetting.showtoolbarConfig = extendsetting.showtoolbarConfig;
    admgridConfigsetting.showinfobar = extendsetting.showinfobar;
    admgridConfigsetting.showsheetbar = extendsetting.showsheetbar;
    admgridConfigsetting.showsheetbarConfig = extendsetting.showsheetbarConfig;
    admgridConfigsetting.showstatisticBar = extendsetting.showstatisticBar;
    admgridConfigsetting.showstatisticBarConfig = extendsetting.showstatisticBarConfig;
    admgridConfigsetting.sheetFormulaBar = extendsetting.sheetFormulaBar;
    admgridConfigsetting.cellRightClickConfig = extendsetting.cellRightClickConfig;
    admgridConfigsetting.sheetRightClickConfig = extendsetting.sheetRightClickConfig;
    admgridConfigsetting.pointEdit = extendsetting.pointEdit;
    admgridConfigsetting.pointEditUpdate = extendsetting.pointEditUpdate;
    admgridConfigsetting.pointEditZoom = extendsetting.pointEditZoom;

    admgridConfigsetting.userInfo = extendsetting.userInfo;
    admgridConfigsetting.userMenuItem = extendsetting.userMenuItem;
    admgridConfigsetting.myFolderUrl = extendsetting.myFolderUrl;
    admgridConfigsetting.functionButton = extendsetting.functionButton;

    admgridConfigsetting.showConfigWindowResize = extendsetting.showConfigWindowResize;
    admgridConfigsetting.enableAddRow = extendsetting.enableAddRow;
    admgridConfigsetting.enableAddBackTop = extendsetting.enableAddBackTop;
    admgridConfigsetting.addRowCount = extendsetting.addRowCount;
    admgridConfigsetting.enablePage = extendsetting.enablePage;
    admgridConfigsetting.pageInfo = extendsetting.pageInfo;

    admgridConfigsetting.editMode = extendsetting.editMode;
    admgridConfigsetting.beforeCreateDom = extendsetting.beforeCreateDom;
    admgridConfigsetting.workbookCreateBefore = extendsetting.workbookCreateBefore;
    admgridConfigsetting.workbookCreateAfter = extendsetting.workbookCreateAfter;
    admgridConfigsetting.remoteFunction = extendsetting.remoteFunction;
    admgridConfigsetting.customFunctions = extendsetting.customFunctions;

    admgridConfigsetting.fireMousedown = extendsetting.fireMousedown;
    admgridConfigsetting.forceCalculation = extendsetting.forceCalculation;
    admgridConfigsetting.plugins = extendsetting.plugins;

    admgridConfigsetting.rowHeaderWidth = extendsetting.rowHeaderWidth;
    admgridConfigsetting.columnHeaderHeight = extendsetting.columnHeaderHeight;

    admgridConfigsetting.defaultColWidth = extendsetting.defaultColWidth;
    admgridConfigsetting.defaultRowHeight = extendsetting.defaultRowHeight;

    admgridConfigsetting.title = extendsetting.title;
    admgridConfigsetting.container = extendsetting.container;
    admgridConfigsetting.hook = extendsetting.hook;

    admgridConfigsetting.pager = extendsetting.pager;

    admgridConfigsetting.initShowsheetbarConfig = false;

    admgridConfigsetting.imageUpdateMethodConfig = extendsetting.imageUpdateMethodConfig;

    if (Store.lang === 'zh') flatpickr.localize(Mandarin.zh);

    // Store the currently used plugins for monitoring asynchronous loading
    Store.asyncLoad.push(...admgridConfigsetting.plugins);

    // Register plugins
    initPlugins(extendsetting.plugins , extendsetting.data);

    // Store formula information, including internationalization
    functionlist(extendsetting.customFunctions);

    let devicePixelRatio = extendsetting.devicePixelRatio;
    if(devicePixelRatio == null){
        devicePixelRatio = 1;
    }
    Store.devicePixelRatio = Math.ceil(devicePixelRatio);

    //loading
    const loadingObj=admgridlodingHTML("#" + container)
    Store.loadingObj=loadingObj

    if (loadurl == "") {
        sheetmanage.initialjfFile(menu, title);
        // admgridsizeauto();
        initialWorkBook();
    }
    else {
        $.post(loadurl, {"gridKey" : server.gridKey}, function (d) {
            let data = new Function("return " + d)();
            Store.admgridfile = data;

            sheetmanage.initialjfFile(menu, title);
            // admgridsizeauto();
            initialWorkBook();

            //需要更新数据给后台时，建立WebSocket连接
            if(server.allowUpdate){
                server.openWebSocket();
            }
        });
    }
}

function initialWorkBook(){
    admgridHandler();//Overall dom initialization
    initialFilterHandler();//Filter initialization
    initialMatrixOperation();//Right click matrix initialization
    initialSheetBar();//bottom sheet bar initialization
    formulaBarInitial();//top formula bar initialization
    rowColumnOperationInitial();//row and coloumn operate initialization
    keyboardInitial();//Keyboard operate initialization
    orderByInitial();//menu bar orderby function initialization
    zoomInitial();//zoom method initialization
    printInitial();//print initialization
    initListener();
}

//获取所有表格数据
admgrid.getadmgridfile = getadmgridfile;

//获取当前表格 选区
admgrid.getadmgrid_select_save = getadmgrid_select_save;

//设置当前表格 选区
admgrid.setadmgrid_select_save = setadmgrid_select_save;

//获取当前表格 config配置
admgrid.getconfig = getconfig;

//二维数组数据 转化成 {r, c, v}格式 一维数组 (传入参数为二维数据data)
admgrid.getGridData = sheetmanage.getGridData;

//生成表格所需二维数组 （传入参数为表格数据对象file）
admgrid.buildGridData = sheetmanage.buildGridData;

// Refresh the canvas display data according to scrollHeight and scrollWidth
admgrid.admgridrefreshgrid = admgridrefreshgrid;

// Refresh canvas
admgrid.jfrefreshgrid = jfrefreshgrid;

// Get the value of the cell
admgrid.getcellvalue = getcellvalue;

// Set cell value
admgrid.setcellvalue = setcellvalue;

// Get selection range value
admgrid.getdatabyselection = getdatabyselection;

admgrid.sheetmanage = sheetmanage;

// Data of the current table
admgrid.flowdata = function () {
    return Store.flowdata;
}

// Set selection highlight
admgrid.selectHightlightShow = selectHightlightShow;

// Reset parameters after destroying the table
admgrid.destroy = method.destroy;

admgrid.showLoadingProgress = showloading;
admgrid.hideLoadingProgress = hideloading;
admgrid.admgridextendData = admgridextendData;

admgrid.locales = locales;

export {
    admgrid
}
